import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import { Subtitle } from "../components/Subtitle";
import { Contact } from "../components/Contact";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const GoalsPage = () => (
  <Page page="Tavoitteet">
    <Row>
      <Col>
        <Title>Liukastumiset maksavat "maltaita"</Title>

        <Content>
          Suomessa liukastumisonnettomuuksia tapahtuu vuosittain kymmeniä
          tuhansia ja niistä koituu yhteiskunnalle vuodessa jopa 420 miljoonan
          euron menot. Vakuutusyhtiöt maksavat liukastumisonnettomuuksista
          korvauksia vuosittain noin 19 miljoonaa euroa. Lahtelaisille tehdyssä
          kyselyssä 22% vastanneista ilmoitti liukastuneensa hoitoa
          edellyttävästi edellisten kolmen vuoden aikana.
        </Content>

        <Subtitle>
          Liukastumisriski kasvaa iän myötä, mutta myös nuoret murtavat luitaan.
        </Subtitle>

        <Content>
          Terveyskeskusten mukaan valtaosa vakavista liukastumisonnettomuuksista
          tapahtuu muutamana, erittäin liukkaana päivänä. Useimmiten liukkaus on
          päässyt yllättämään. Esimerkiksi iljanteisen jään päälle on satanut
          ohut, petollinen lumikerros. Etukäteisvaroitus liukkaudesta olisi
          estänyt valtaosan liukastumisista. Olisi osattu varautua ja
          varustautua liukkautta varten.
        </Content>

        <Subtitle>
          Jo yksi lonkkaleikkaus maksaa enemmän kuin varoituspalvelu!
        </Subtitle>

        <Content>
          Arvioiden mukaan varoituspalvelulla voidaan ehkäistä ainakin 10 %
          liukastumisonnettomuuksista. Esimerkiksi 50.000 asukkaan kaupungissa
          ehkäistäisiin tällöin jopa 50 sairaalahoitoa edellyttävää
          liukastumista vuosittain. Tästä saatava yhteiskunnallinen hyöty on
          noin 400.000 euroa. Palvelun ylläpito korkeatasoisella
          asiakaspalvelulla maksaa 50.000 asukkaan kaupungille alle 10.000 euroa
          vuodessa.
        </Content>

        <Content>
          <b>
            Toimintamalli on helposti laajennettavissa muihinkin kaupunkeihin ja
            kuntiin. Jos olet päättäjä tai muu vaikuttaja ja kiinostunut
            palvelusta, ota yhteyttä palvelun koordinaattoriin:
          </b>
        </Content>

        <Content>
          <Contact />
        </Content>
      </Col>
    </Row>
  </Page>
);

export default GoalsPage;
